import Image from "next/image";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper";

import styles from "styles/containers/highlights.module.scss";

import { Currency, OlgaImageLoader } from "shared/tools";
import { useEffect, useState } from "react";

export default function Highlights({ products, onSelectProduct }) {
  const [highlights, setHighlights] = useState([]);

  const loadHighlights = () => {
    const extractProductsCategorys = products.map((item) => item);
    const res = extractProductsCategorys.map((item) => item.items);

    res
      .flat()
      .filter((item) => item.highlight && item.show && item.visible)
      .map((item) => {
        setHighlights([...highlights, item]);
      });
  };

  useEffect(() => {
    loadHighlights();
  }, []);

  const mountPrice = (item) => {
    if (item.price > 0) {
      return Currency.formatCurrency(Number(item.price));
    }

    if (item.price >= 0 && item.sizes.length === 0) {
      return Currency.formatCurrency(Number(item.price));
    }

    if (item.sizes.length > 0) {
      const minValue = (item.sizes || []).reduce((prev, current) =>
        prev.price < current.price ? prev : current, 0
      );

      if (minValue.price === 0) {
        if (item.items.length > 0) {
          let totalPrice = 0;
          (item.items || []).forEach((subitem) => {
            if (subitem.min > 0) {
              const minValue = (subitem.items || []).reduce((prev, current) =>
                prev.price < current.price ? prev : current, 0
              );
              totalPrice = totalPrice + minValue.price;
            }
          });

          return Currency.formatCurrency(Number(totalPrice));
        }
      }

      return Currency.formatCurrency(Number(minValue.price));
    }
  };

  return highlights.length > 0 ? (
    <div className={styles.highlights}>
      <p className={styles.categoryName}>Destaques</p>

      <Swiper
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        centeredSlides={highlights.length > 4}
        loop={highlights.length > 4}
        breakpoints={{
          992: {
            slidesPerView: 4,
            spaceBetween: 15,
          },
          0: {
            slidesPerView: 1.5,
            spaceBetween: 10,
          },
        }}
      >
        {highlights.map((highlight, _i) => {
          return (
            <SwiperSlide key={_i}>
              <div
                className={styles.highlight}
                onClick={() => onSelectProduct(highlight)}
              >
                <div className={styles.picture}>
                  <Image
                    src={`${process.env.NEXT_PUBLIC_CDN_ASSETS}/${highlight.image}`}
                    alt={highlight.name}
                    placeholder="blur"
                    blurDataURL={OlgaImageLoader.BLURRED_IMG}
                    loader={OlgaImageLoader.load}
                    layout="fill"
                    sizes="100vw"
                  />
                </div>
                <div className={styles.details}>
                  <p className={styles.name}>{highlight.name}</p>
                  <p className={styles.description}>{highlight.description}</p>
                  <p className={styles.price}>
                    {(highlight.sizes || []).length > 1
                      ? `A partir de ${mountPrice(highlight)}`
                      : mountPrice(highlight)}
                  </p>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  ) : null;
}
