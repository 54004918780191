import styles from "styles/containers/order-tracking.module.scss";

import { Drawer } from "shared/components";
import { useEffect, useState } from "react";

import { BaseStorage } from "storage/BaseStorage";
import { ShippingHubAPI } from "api/ShippingHubAPI";

export default function OrderTracking({ open, onClose, selectedOrder }) {
  const [trackingUrl, setTrackingUrl] = useState("");
  const [orderId, setOrderId] = useState("");

  const loadTracking = async () => {
    const unitConfigurations =
      BaseStorage.getItem(selectedOrder.environment_alias) || {};
    const { shippinghub } = unitConfigurations || {};
    const trackingResponse = await ShippingHubAPI.getOrder({
      accountId: shippinghub.accountId,
      gatewayType: shippinghub.gatewayType,
      orderId: selectedOrder.id,
      publicKey: shippinghub.publicKey,
    });

    const { trackingUrl } = trackingResponse.getData({});
    if (trackingUrl) {
      if (shippinghub.gatewayType === "MOTTU") {
        setTimeout(() => {
          window.open(trackingUrl, "_blank");
        });
        onClose(false);
      } else {
        setTrackingUrl(trackingUrl);
        setOrderId(selectedOrder?.id || "");
      }
    }
  };

  useEffect(() => {
    if (open && selectedOrder?.id && selectedOrder?.environment_alias)
      loadTracking();
  }, [open, selectedOrder]);

  return (
    <>
      {selectedOrder?.delivery?.deliveryPlatformShipping ===
        "IFOOD_DELIVERY" && (
        <Drawer
          open={open}
          onClose={() => {
            onClose();
          }}
          title={`Pedido #${selectedOrder.displayId}`}
          showCloseButton
        >
          <div className={styles.orderContainer}>
            <div className={styles.orderTracking}>
              <iframe src={trackingUrl} />
            </div>
          </div>
        </Drawer>
      )}
    </>
  );
}
