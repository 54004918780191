import { Request } from "@konv/http";

export class ShippingHubAPI {
  static async requestAuthenticated({
    endpoint = "/",
    method = "GET",
    headers = {},
    query,
    data,
  }) {
    return await Request.request({
      endpoint: endpoint,
      cors: true,
      method: method || "GET",
      headers: headers || {},
      service: "shipping-hub",
      ignoreVersion: true,
      params: query || {},
      data: data || {},
    });
  }

  static async getOrder({ accountId, publicKey, orderId, gatewayType }) {
    return await ShippingHubAPI.requestAuthenticated({
      endpoint: `/${accountId}/${gatewayType}/orders/${orderId}`,
      headers: {
        Authorization: "Bearer " + publicKey,
      },
      method: "GET"
    });
  }
  
  static async getPublicConfigurations({ accountId, publicKey, gatewayType }) {
    return await ShippingHubAPI.requestAuthenticated({
      endpoint: `/${accountId}/${gatewayType}/configurations/public`,
      headers: {
        Authorization: "Bearer " + publicKey,
      },
      method: "GET"
    });
  }
}
