import { useRef } from "react";

import Image from "next/image";

import styles from "styles/containers/product.module.scss";

import { CatalogHelpers, OlgaImageLoader } from "shared/tools";

import { NoImage } from "shared/components/Icons";
import slugify from "slugify";

const isPromotionalPrice = (product) => {
  if (!product?.sizes || product?.sizes?.length <= 0) return false;

  return !!product.sizes.find((size) => size.promo_price === true);
};

const CategoryItem = ({ category, onSelectProduct, firstLCP }) => {
  const ref = useRef(null);

  const someProductIsVisible = category.items.some((i) => i.visible);

  return (
    someProductIsVisible &&
    category.items.length > 0 &&
    category.visible &&
    category.available && (
      <div ref={ref} id={`categoria-${slugify(category.name.toLowerCase())}`}>
        <h2 className={styles.categoryName}>{category.name}</h2>
        {category.description && (
          <p className={styles.categoryDescription}>{category.description}</p>
        )}
        <div className={styles.products}>
          {(category.items || []).map((item, _i) => {
            const isPizza = item.type === "pizza";
            const oldPrice = CatalogHelpers.mountOldPrice(item);
            const hasSizes =
              (item.sizes || []).length > 1 || (item.items || []).length > 0;

            return (
              item.show &&
              item.available &&
              item.visible && (
                <div
                  key={_i}
                  className={styles.product}
                  onClick={() => onSelectProduct(item, category)}
                >
                  {!isPizza ? (
                    <div className={styles.image}>
                      {item.image ? (
                        <Image
                          src={`${process.env.NEXT_PUBLIC_CDN_ASSETS}/${item.image}`}
                          alt={item.name}
                          placeholder="blur"
                          blurDataURL={OlgaImageLoader.BLURRED_IMG}
                          loader={OlgaImageLoader.load}
                          width={100}
                          height={100}
                          loading={firstLCP ? "eager" : "lazy"}
                          priority={firstLCP}
                          sizes="100px"
                        />
                      ) : (
                        <div className={styles.noImage}>
                          <NoImage />
                        </div>
                      )}
                    </div>
                  ) : null}
                  <div className="d-flex flex-col flex-1">
                    <p className={styles.name}>{item.name}</p>
                    <p className={styles.description}>{item.description}</p>

                    {
                      isPromotionalPrice(item) && oldPrice && !hasSizes ? (
                        <p className={styles.fromPrice}>
                          <strike>{oldPrice}</strike>&nbsp;&nbsp;
                          <span className={styles.price}>
                            {CatalogHelpers.mountPrice(item)}
                          </span>
                        </p>
                      ) : isPromotionalPrice(item) && hasSizes ? (
                        <p className={styles.fromPrice}>
                          A partir de&nbsp;
                          <span className={styles.price}>
                            {CatalogHelpers.mountPrice(item)}
                          </span>
                          &nbsp;&nbsp;
                          <strike>{oldPrice}</strike>
                        </p>
                      ) : hasSizes ? (
                        <p className={styles.fromPrice}>
                          A partir de&nbsp;
                          <span className={styles.price}>
                            {CatalogHelpers.mountPrice(item)}
                          </span>
                        </p>
                      ) : (
                        <p className={styles.fromPrice}>
                          {CatalogHelpers.mountPrice(item)}
                        </p>
                      )
                    }
                  </div>
                </div>
              )
            );
          })}
        </div>
      </div>
    )
  );
};

export default function Products({
  categories,
  onSelectProduct,
  onCategoryVisible,
}) {
  return (
    <div className={styles.productsContainer}>
      {categories.map((category, index) => (
        <CategoryItem
          key={index}
          category={category}
          onCategoryVisible={onCategoryVisible}
          onSelectProduct={(product) =>
            onSelectProduct && onSelectProduct(category, product)
          }
          firstLCP={index === 0}
        />
      ))}
    </div>
  );
}
