/* eslint-disable @next/next/no-img-element */
import { useEffect, useState } from "react";

import dynamic from "next/dynamic";

import styles from "styles/pages/environment.module.scss";

import debounce from "lodash.debounce";

import { InputSearch, Drawer, Loading, Button } from "@/shared/components";

import Image from "next/image";

import Products from "@/shared/containers/Catalog/Products";
import Highlights from "@/shared/containers/Catalog/Highlights";
import AddressMinified from "@/shared/containers/Address/AddressMinified";
import OrderTracking from "@/shared/containers/Order/OrderTracking";

import useEventListener from "@/shared/hooks/useEventListener";

import { Catalog } from "@/shared/core/Catalog";
import { Brand, BrandEvents } from "@/shared/core/Brand";
import { Store, StoreEvents } from "@/shared/core/Store";
import { OrderStorage } from "storage/OrderStorage";

import { isDesktop, isMobile } from "react-device-detect";

import { DataLayer } from "@/shared/tools/DataLayer";
import { Customer } from "@/shared/core/Customer";
import { PaymentHubAPI } from "api/PaymentHub";
import { PaymentStorage } from "storage/PaymentStorage";

import MenuMobile from "../layouts/MenuMobile";
import Header from "../layouts/Header";

import BrandBanners from "../containers/Home/Banner";

import classNames from "classnames/bind";
import { EcleticaCatalog } from "../core/EcleticaCatalog";
import { Flows } from "../core/Enum";
import { Table } from "../core/Table";
import { useRouter } from "next/router";
const css = classNames.bind(styles);

const CategoriesDesktop = dynamic(() =>
  import("@/shared/containers/Catalog/CategoriesDesktop")
);
const CategoriesMobile = dynamic(() =>
  import("@/shared/containers/Catalog/CategoriesMobile")
);
const StoreHeader = dynamic(() => import("@/shared/containers/StoreHeader"));

const ProductDetails = dynamic(() =>
  import("@/shared/containers/Product/ProductDetails")
);
const OrderDetails = dynamic(() =>
  import("@/shared/containers/Order/OrderDetails")
);
const Cart = dynamic(() => import("@/shared/containers/Cart/Cart"));
const Addressess = dynamic(() =>
  import("@/shared/containers/Address/Addressess")
);
const LoginOrRegister = dynamic(() =>
  import("@/shared/containers/Account/LoginOrRegister")
);

const executeOnSchema = (item, callback) => {
  for (let field of [
    "items",
    "extra_ingredients",
    "edges",
    "doughs",
    "sizes",
  ]) {
    if (item[field] && item[field].length > 0) {
      for (let children of item[field]) {
        executeOnSchema(children, callback);
        callback(children, item, field);
      }
    }
  }
};

const verifyUnit = () => {
  return !!Store.alias;
};

const hasString = (haystack, needle) =>
  (haystack || "").toLowerCase().indexOf(needle) > -1;

export function BrandIndex() {
  const [catalog, setCatalog] = useState({ items: [] });
  const [cartItemIndex, setCartItemIndex] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [openProductDetails, setOpenProductDetails] = useState(false);
  const [openOrderDetails, setOpenOrderDetails] = useState(false);
  const [openOrderTracking, setOpenOrderTracking] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [openCart, setOpenCart] = useState(false);
  const [openAddressess, setOpenAddressess] = useState(false);
  const [openLoginOrRegister, setOpenLoginOrRegister] = useState(false);
  const [loadingCatalog, setLoadingCatalog] = useState(true);
  const [isDigitalMenu, setIsDigitalMenu] = useState(false);
  const [openReadQrCode, setOpenReadQrCode] = useState(false);

  const [loading, setLoading] = useState(true);
  const [orderFlow, setOrderFlow] = useState(null);

  const [tagManagerLoaded, setTagManagerLoaded] = useState(false);
  const [customer] = useState(Customer.getCustomer());

  useEffect(() => {
    setOrderFlow(OrderStorage.flow);
  }, []);

  useEffect(() => {
    DataLayer.push({
      event: "page_view",
      page_title: "Cardápio",
    });
  }, []);

  function insertGTM(gtm) {
    DataLayer.init();
    DataLayer.push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    });
    var f = document.getElementsByTagName("script")[0],
      j = document.createElement("script"),
      dl = "dataLayer" != "dataLayer" ? "&l=" + "dataLayer" : "";
    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + gtm + dl;
    j.id = "google-tag-manager";
    f.parentNode.insertBefore(j, f);
    window, document, "script", "dataLayer", gtm;
  }

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);

      const tagManagerScript = document.getElementById("google-tag-manager");

      if (tagManagerScript) tagManagerScript.remove();

      if (Brand.google_tag_manager_key) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.text = insertGTM(Brand.google_tag_manager_key);
        document.head.appendChild(script);
        setTagManagerLoaded(true);
      }
    }, 500);

    const availabityTimer = setInterval(() => {
      updateCatalogAvailability();
    }, 1000 * 10);

    return () => clearInterval(availabityTimer);
  }, []);

  const updateCatalogAvailability = () => {
    setCatalog((currentCatalog) => {
      return Catalog.updateCatalogAvailability(currentCatalog);
    });
  };

  useEffect(() => {
    setTimeout(() => {
      if (tagManagerLoaded) {
        DataLayer.push({
          event: "visitorStatus",
          visitorLoginState: !!customer,
          userId: customer?.id || null,
        });
      }
    }, 1000);
  }, [customer, tagManagerLoaded]);

  const onSelectProduct = (category, product) => {
    if (!verifyUnit()) {
      setOpenAddressess(true);
      return;
    }

    for (const item of catalog.items) {
      if (item.id == product.parent) {
        product.extra_ingredients = [...item.extra_ingredients];
        break;
      }
    }

    setOpenProductDetails(true);
    setSelectedProduct(JSON.parse(JSON.stringify(product)));
    setCartItemIndex(null);

    const productDataLayer = {
      event: "view_item",
      ecommerce: {
        currency: "BRL",
        value: DataLayer.mountPrice(product),
        items: [
          {
            item_id: product.id,
            item_name: product.name,
            item_brand: Brand.getBrand()?.name,
            price: DataLayer.mountPrice(product),
          },
        ],
      },
    };

    DataLayer.push({ ecommerce: null });
    DataLayer.push(productDataLayer);

    DataLayer.push({ ecommerce: null });
    DataLayer.push({
      event: "select_item",
      ecommerce: {
        item_list_id: Brand.alias,
        item_list_name: "Cardápio",
        items: [
          {
            item_id: product.id,
            item_name: product.name,
            item_brand: Brand.getBrand()?.name,
            price: DataLayer.mountPrice(product),
          },
        ],
      },
    });
  };

  const onLoadWithClub = async () => {
    const queryString = window.location.search;
    const searchParams = new URLSearchParams(queryString);
    const customerPhone = searchParams.get("q");
    const session = sessionStorage.getItem("customerPhone");
    const openStep = session || customerPhone;
    const openDrawerReload = sessionStorage.getItem("openDrawerReload");
    // if (openDrawerReload || openStep) {
    //   if (isMobile) {
    //     router.replace("/beneficios");
    //   } else {
    //     setOpenBenefits(true);
    //   }
    //   sessionStorage.setItem("openDrawerReload", false);
    // }
    // sessionStorage.setItem("openDrawerReload", true);
  };

  useEventListener(BrandEvents.BRAND_CHANGED, async () => {
    try {
      if (Store.id) {
        // const delivery = OrderStorage.getDelivery();
        // await Store.setStoreByAddress({
        //   unit: { alias: Store.alias },
        //   delivery,
        // });
      } else {
        // await Store.setStoreByAddress();
      }
    } catch (err) {
      console.log("Não foi possível selecionar o endereço", err);
    }
  });

  useEventListener(StoreEvents.STORE_CHANGED, async () => {
    if (Customer.id) {
      const cardsResponse = await PaymentHubAPI.PagarMeGetCards(Customer.id);
      PaymentStorage.loadCards(cardsResponse.getData([]));
    }

    await EcleticaCatalog.install(Store.alias);

    const catalogResponse = await Store.catalog();
    OrderStorage.updateCartCatalogService(catalogResponse.service);

    executeOnSchema(catalogResponse, (i) => {
      i.visible = true;
      i.available = true;
    });
    setLoadingCatalog(false);

    const availableCatalog = Catalog.updateCatalogAvailability(catalogResponse);
    setCatalog(availableCatalog);

    const getProducts = () => {
      let products = [];
      availableCatalog.items.map((item) =>
        item.items.map((b) => products.push(b))
      );

      return products.map((product, index) => {
        return {
          item_id: product.id,
          item_name: product.name,
          index,
          price: DataLayer.mountPrice(product),
          item_brand: Brand.getBrand()?.name,
        };
      });
    };

    DataLayer.push({ ecommerce: null });
    DataLayer.push({
      event: "view_item_list",
      ecommerce: {
        item_list_id: Brand.alias,
        item_list_name: "Cardápio",
        items: getProducts(),
      },
    });
  });

  useEffect(() => {
    onLoadWithClub();
    // window.addEventListener("scroll", handleScroll, { passive: true });
    // return () => {
    //   window.removeEventListener("scroll", handleScroll, { passive: true });
    // };
  }, []);

  const onSearchItemOnCatalog = (e) => {
    const searchTerm = (e.target.value || "").toLowerCase();
    let ocurrences = 0;
    const newCatalog = JSON.parse(JSON.stringify(catalog));

    DataLayer.push({
      event: "search",
      search_term: searchTerm,
      origin_type: "search",
    });

    executeOnSchema(newCatalog, (item) => {
      item.visible =
        !searchTerm ||
        hasString(item.name, searchTerm) ||
        hasString(item.description, searchTerm);
      if (item.visible) {
        ocurrences++;
      } else {
        for (let field of [
          "items",
          "extra_ingredients",
          "edges",
          "doughs",
          "sizes",
        ]) {
          if (item[field] && item[field]?.length > 0) {
            for (let children of item[field]) {
              if (children.visible) {
                item.visible = true;
                break;
              }
            }
            if (item.visible) {
              break;
            }
          }
          if (item.visible) {
            break;
          }
        }
      }
    });

    setCatalog(Catalog.updateCatalogAvailability(newCatalog));
  };

  const handleScroll = () => {
    if (typeof window != "undefined") {
      const searchAndCategories = document.getElementById(
        "searchAndCategories"
      );
      const brandBanners = document.getElementById("brand-banners");
      let backgroundHeight = 175;
      if (brandBanners) backgroundHeight = brandBanners.offsetHeight + 155;

      const y = window.scrollY;

      if (searchAndCategories && !isMobile) {
        y >= backgroundHeight
          ? searchAndCategories.classList.add(styles.fixed)
          : searchAndCategories.classList.remove(styles.fixed);
      }
    }
  };

  const setOrder = (order) => {
    setSelectedOrder(order);
    setOpenOrderDetails(true);
  };

  const banners = Store.getDeviceBanners(isMobile ? "mobile" : "desktop");

  useEffect(() => {
    const externalId = Table.externalId;
    if (externalId === Table.isDigitalMenu) setIsDigitalMenu(true);
    else setIsDigitalMenu(false);
  }, [])

  const router = useRouter();

  if (!Brand.alias || !Store.alias) {
    return (
      <Drawer
        open={true}
        title="Atenção!"
        subtitle="Esta tela só é acessível via leitura de QR Code, utilize o botão abaixo para voltar a tela de escaneamento."
        direction="bottom-center"
      >
        <Button design="primary" onClick={() => router.replace("/")}>
          Voltar para escaneamento
        </Button>
      </Drawer>
    );
  }

  return (
    <>
      {loading && (
        <div className={styles.loading}>
          <Loading />
        </div>
      )}

      {!loading && (
        <>
          {orderFlow === Flows.DELIVERY ? (
            <>
              <BrandBanners />

              <AddressMinified />
            </>
          ) : null}

          <div
            className={css({
              environmentContainer: true,
              isDelivery: orderFlow === Flows.DELIVERY,
              isDigitalMenu: isDigitalMenu,
              marginTop:
                banners && banners.length > 0 && orderFlow === Flows.DELIVERY,
            })}
          >
            <div className={styles.storeHeader}>
              <StoreHeader />

              <div className={styles.search}>
                <InputSearch
                  placeholder="Procurar no cardápio..."
                  onChange={debounce(onSearchItemOnCatalog, 1000)}
                />

                {catalog.items.length > 0 && isDesktop && (
                  <div
                    className={styles.searchAndCategories}
                    id="searchAndCategories"
                  >
                    <div className={styles.categories}>
                      <CategoriesDesktop categories={catalog.items} />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className={styles.catalogContainer}>
              {loadingCatalog ? (
                <div className={styles.container}>
                  <div className={styles.shimmerCatalog}>
                    <div className={`${styles.title} ${styles.shimmer}`} />
                    <div className={styles.highlightsDesktop}>
                      {Array.from({ length: 5 }).map((_, _i) => {
                        return (
                          <div className={styles.shimmerContainer} key={_i}>
                            <div
                              className={`${styles.item} ${styles.shimmer}`}
                            />
                          </div>
                        );
                      })}
                    </div>

                    <div className={styles.highlightsMobile}>
                      {Array.from({ length: 3 }).map((_, _i) => {
                        return (
                          <div className={styles.shimmerContainer} key={_i}>
                            <div
                              className={`${styles.item} ${styles.shimmer}`}
                            />
                          </div>
                        );
                      })}
                    </div>

                    <div className={`${styles.title} ${styles.shimmer}`} />
                    <div className={styles.products}>
                      {Array.from({ length: 4 }).map((_, _i) => {
                        return (
                          <div className={styles.shimmerContainer} key={_i}>
                            <div
                              className={`${styles.item} ${styles.shimmer}`}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ) : catalog?.items?.length > 0 ? (
                <>
                  <Highlights
                    products={catalog.items}
                    onSelectProduct={(product) =>
                      onSelectProduct(null, product)
                    }
                  />

                  <CategoriesMobile categories={catalog.items} />

                  <div className={styles.containerB}>
                    <Products
                      categories={catalog.items}
                      onSelectProduct={(category, product) =>
                        onSelectProduct(category, product)
                      }
                    />
                  </div>
                </>
              ) : (
                <div className={styles.emptyCatalog}>
                  <p>Não há nenhum produto cadastrado.</p>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      <Cart
        open={openCart}
        onClose={() => setOpenCart(false)}
        setOpenBenefits={() => setOpenBenefits(true)}
        selectDeliveryAddress={() => setOpenAddressess(true)}
        selectPaymentMethod={() => setOpenPaymentMethods(true)}
        onLoginOrRegister={() => setOpenLoginOrRegister(true)}
        onCheckout={(order) => {
          setOpenCart(false);
          setOrder(order);
        }}
      />

      <Drawer
        open={openProductDetails}
        onClose={(status) => setOpenProductDetails(status)}
        withoutPadding
        alreadyDrawer={openCart}
      >
        <ProductDetails
          cartItemIndex={cartItemIndex}
          product={selectedProduct}
          onClose={() => setOpenProductDetails(false)}
          alreadyDrawer={openCart}
        />
      </Drawer>

      <Addressess
        open={openAddressess}
        onClose={(status) => setOpenAddressess(status)}
        alreadyDrawer={openCart}
      />

      <OrderDetails
        open={openOrderDetails}
        onClose={() => {
          setOpenOrderDetails(false);
          setOpenOrderTracking(false);
        }}
        selectedOrder={selectedOrder}
        onTracking={() => setOpenOrderTracking(true)}
        alreadyDrawer
      />

      <OrderTracking
        open={openOrderTracking}
        onClose={() => {
          setOpenOrderTracking(status);
        }}
        selectedOrder={selectedOrder}
      />

      <LoginOrRegister
        open={openLoginOrRegister}
        onClose={(status) => setOpenLoginOrRegister(status)}
        setIsLogged={(status) => loadUser(status)}
        alreadyDrawer={openCart}
      />
    </>
  );
}
